export const EmailReportGridColumnName = {
    CandidateName: "Candidate name",
    Email: "Email",
    EmailName: "Email name",
    BouncedDate: "Bounced date",
    OpenDate: "Open date",
    LinkClicked: "Link clicked",
    Delivered: "Delivered",
    LinkTitle: "LinkTitle",
    CampaignName: "Campaign name",
    AutomationName: "Campaign name",
    LinkName: "Link name",
    Url: "Url",
    Clicks: "Clicks",
    Opened: "Opened",
    Click: "Click",
    UnsubscribedDate: "Unsubscribed date",
    LastSentDate: "Last sent date",
    LastSendDate: "Last send date",
    TotalSent: "Total sent",
    Bounced: "Bounced",
    Unsubscribed: "Unsubscribed",
    Clicked: "Clicked",
    SurveyOpened: "Survey opened",
    SurveyClicked: "Survey clicked",
    Processed: "Processed",
    SendDate: "Send date",
    Status: "Status",
    Category: "Category",
    Invalid: "Invalid"
};
