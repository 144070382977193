const AutomationReportGridColumnName = {
  AutomationName: "Campaign name",
  AutomationId: "Campaign Id",
  AutomationCreatedDate: "Date activated",
  AutomationCreatedBy: "Activated by",
  AutomationDescription: "Description",
  AutomationStatus: "Status",
  AutomationAction: "",
  CandidateName: "Candidate name",
  CompletionDate: "Completion date",
  HasMet: "Has Met",
  AutomationCategory: "Category"
};

const AutomationReportGridFieldName = {
  AutomationRow: "automationRowNum",
  AutomationName: "automationName",
  AutomationId: "automationId",
  AutomationCreatedDate: "createdAt",
  AutomationCreatedBy: "createdBy",
  AutomationDescription: "automationDescription",
  AutomationStatus: "state",
  AutomationAction: "action",
  AutomationCategory: "category"
};


const AutomationReportClientGridColumnName = {
  AutomationName: "Campaign name",
  AutomationCreatedDate: "Date activated",
  AutomationDescription: "Description",
  AutomationStatus: "Status",
  AutomationAction: "",
  ClientName: "Contact name",
  CompletionDate: "Completion date",
  HasMet: "Has Met"
};

const AutomationReportClientGridFieldName = {
  AutomationRow: "automationRowNum",
  AutomationName: "automationName",
  AutomationCreatedDate: "createdAt",
  AutomationDescription: "automationDescription",
  AutomationStatus: "state",
  AutomationAction: "action",
};

const AutomationReportFilterModel = {
  CandidateFilterModelName: "candidateFilterModel",
  ClientFilterModelName: "clientFilterModel",
}

export { AutomationReportGridColumnName, AutomationReportGridFieldName, AutomationReportClientGridColumnName, AutomationReportClientGridFieldName, AutomationReportFilterModel };