import { observer } from "mobx-react";
import { RappidNodeType } from "helpers/Constants";
import { Button } from "reactstrap";
import { AddNodeToCanvas, EditRappidNode } from "components/Rappid/RappidCanvasFunctions";
import { automationStore } from "stores";
import { Enum } from "helpers";
import * as _ from "lodash";

// Submit Button Handles adding new Steps to the canvas, 
const SubmitButton = observer(({
  type = RappidNodeType.Email,
  text = "",
  text2 = "",
  text3 = "",
  text4 = "",
  roiData,
  isDisabled = false,
  isUpdate = automationStore.isSidebarFormUpdate
}) => {
  roiData = roiData || {};
  return (
    <Button
      id={`btn-add-${type}`}
      name={`btn-add-${type}`}
      onClick={() => {
        const { isCopiedTemplate } = automationStore;
        automationStore.resetAutomationSidebarFormDirty();

        if (type === RappidNodeType.Delay) {
          const { formDelay } = automationStore;
          const data = { ...formDelay, roiData: { ...roiData } };
          automationStore.setCurrentPreviousDelayId("");
          automationStore.setFormDelay(data, true);
        }

        if (type === RappidNodeType.Trigger) {
          const { selectedAutomationAudienceIds, selectedAutomationUserIds, selectedAutomationGroupIds, selectedAutomationOfficeIds, selectedIncludeRecentNoteOwner } = automationStore;
          const selectedAudienceIds = [...selectedAutomationAudienceIds];
          automationStore.setAutomationAudiencesIds(selectedAudienceIds);

          const selectedUserIds = [...selectedAutomationUserIds];
          automationStore.setAutomationUserIds(selectedUserIds);

          const selectedGroupIds = [...selectedAutomationGroupIds];
          automationStore.setAutomationGroupIds(selectedGroupIds);

          const selectedOfficeIds = [...selectedAutomationOfficeIds];
          automationStore.setAutomationOfficeIds(selectedOfficeIds);

          automationStore.setIncludeRecentNoteOwner(selectedIncludeRecentNoteOwner);

          automationStore.setIsAutomationFormDirty(true);
          if (!_.isEmpty(roiData) || selectedAudienceIds.length > 0) {
            if (isCopiedTemplate) {
              roiData.forEach(function (element) {
                element.isUpdatedFromCopiedTemplate = true;
              });
            }
            EditRappidNode(type, text, text2, text3, text4, roiData);
            automationStore.setTriggerList(roiData);
          }
        } else {
          if (isCopiedTemplate) {
            if (type === RappidNodeType.IfElse) {
              roiData.forEach(function (element) {
                element.isUpdatedFromCopiedTemplate = true;
              });
            } else {
              roiData.isUpdatedFromCopiedTemplate = true;
            }
          }
          isUpdate ? EditRappidNode(type, text, text2, text3, text4, roiData) : AddNodeToCanvas(type, text, text2, text3, text4, roiData, null, null, true, true);
        }

        if (type === RappidNodeType.Email) {
          const { formEmail } = automationStore;
          const data = { ...formEmail, ownerType: formEmail.isFromOwner ? formEmail.ownerType : null };
          automationStore.setFormEmail(data, true);
        }

        automationStore.setShowSideBar(false);
        automationStore.setShowSideBarForm(false);
        automationStore.resetSidebarForm();
        automationStore.setIsAutomationFormDirty(true);
        automationStore.setAutomationOnClear(false);
        automationStore.setSidebarFormType("");

        if (isCopiedTemplate) {
          automationStore.isAutomationUpdatedFromTemplate();
        }

        if (automationStore.automationId && (automationStore.automationState === Enum.AutomationStateType.Active || automationStore.automationState === Enum.AutomationStateType.Underway))
          automationStore.setIsOpenConfirmationEdit(true);
      }}
      className="btn-roi secondary outline-white my-3 float-right"
      disabled={isDisabled}
    >
      {isUpdate ? "Update" : "Save"}
    </Button>
  );
});

export default SubmitButton;
